import React from 'react';

import brilImg from '../../assets/images/bril.png';
import overtuigdImg from '../../assets/images/overtuigd.jpg';
import ContentBlock from '../partials/contentBlock';
import generateAssets from '../../utils/generateAssets';

const AboutCta = ({ title, image }) => {
	const CtaText = (props) => (
		<div className={['ctaContainer contentText ' + props.color]}>
			<div className="textContainer">
				<h2>{props.title}</h2>
				<a href='mailto:info@primesafety.be' target="_blank">
					<button className={`button ${props.buttonColor}`}>{props.button}</button>
				</a>
			</div>
		</div>
	);

	const CtaImg = (props) => (
		<div className={['ctaContainer contentImg ' + props.color]}>
			<div className="imageContainer">
				<img src={props.imageUrl} className={props.imageFill} alt={`image of ${props.alt}`} />
			</div>
		</div>
	);

	return (
		<div className="contentBlocks">
			<div className="ctaBlock imgText">
				<CtaImg imageUrl={generateAssets(image)} alt={title} color="gray" imageFill="fill" />
				<CtaText title={title} button="Word partner" color="gray" />
			</div>
		</div>
	);
};

export default AboutCta;
