import React, { useEffect, useState, createClass } from 'react';

import backgroundImg from '../assets/images/background-about.jpeg';

import scrollTo from 'gatsby-plugin-smoothscroll';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import cleanHtml from '../utils/cleanHtml';

const Banner = ({ title, text, whyTitle, whyText }) => {
	return (
		<div className="banner">
			<div className="bannerContainer">
				<div className="textBtnContainer">
					<div>
						<h1>{title}</h1>
						<div dangerouslySetInnerHTML={cleanHtml(text)}></div>
						<button className="button" onClick={() => scrollTo('#contactForm')}>contact</button>
					</div>
				</div>
				<img src={backgroundImg} alt="a helmet that serves decoration puropse" />
			</div>
			<div className="bannerUnder">
				<div className="underContainer">
					<div dangerouslySetInnerHTML={cleanHtml(whyTitle)}></div>
					<div dangerouslySetInnerHTML={cleanHtml(whyText)}></div>
				</div>
			</div>
		</div>
	);
};

export default Banner;
