import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Banner from '../components/banner.js';
import Pillars from '../components/about/pillars.js';
import AboutCta from '../components/about/content.js';
import Team from '../components/about/team.js';
import Contact from '../components/contact/contact.js';
import Footer from '../components/footer.js';
import InfoSection from '../components/partials/infoSection';
import Navigation from '../components/navigation.js';

import '../styles/general.scss';
import MetaData from '../components/partials/metaData';

const AboutPage = () => {
	const [cleanData, setCleanData] = useState({});
	const data = useStaticQuery(graphql`
	query MyQuery {
		Craft {
		  entry(slug: "about-us") {
			id
			title
			... on craft_aboutUs_aboutUs_Entry {
			  id
			  heading1
			  whyTitle
			  whyText
			  description
			  seoImage {
				... on craft_assets_Asset {
				  path
				}
			  }
			  whyReasons {
				... on craft_whyReasons_reason_BlockType {
				  id
				  reasonTitle
				  reasonText
				  image {
					id
					... on craft_assets_Asset {
					  id
					  path
					  height
					  width
					}
				  }
				}
			  }
			  aboutText
			  convincedTitle
			  convincedImage {
				... on craft_assets_Asset {
				  id
				  width
				  height
				  path
				}
			  }
			  infoSectionHome {
				... on craft_infoSectionHome_block_BlockType {
				  id
				  imagePosition
				  sectionTitle
				  sectionText
				  sectionImage {
					width
					height
					path
				  }
				  buttonLink
				  linkNewWindow
				}
			  }
			  teamTitle
			  highlightedMember {
				... on craft_employees_default_Entry {
				  id
				  title
				  function
				  singleImage {
					... on craft_assets_Asset {
					  id
					  width
					  path
					  height
					}
				  }
				}
			  }
			}
		  }
		  entries {
			... on craft_employees_default_Entry {
			  id
			  function
			  sectionHandle
			  title
			  singleImage {
				... on craft_assets_Asset {
				  id
				  width
				  path
				  height
				}
			  }
			}
		  }
		}
	  }	  
	`);

	useEffect(() => {
		const entry = data?.Craft;
		setCleanData(entry);
	}, [data]);

	return (
		<div>
			<main>
				<MetaData title={cleanData?.entry?.title} description={cleanData?.entry?.description} seoImage={cleanData?.entry?.seoImage} />
				<Navigation />
				<Banner title={cleanData?.entry?.heading1} text={cleanData?.entry?.aboutText} whyTitle={cleanData?.entry?.whyTitle} whyText={cleanData?.entry?.whyText} />
				<Pillars pillars={cleanData?.entry?.whyReasons} />
				<AboutCta title={cleanData?.entry?.convincedTitle} image={cleanData?.entry?.convincedImage} />
				<InfoSection changeOrder data={cleanData?.entry?.infoSectionHome} />
				<Team title={cleanData?.entry?.teamTitle} highlightedMember={cleanData?.entry?.highlightedMember} members={cleanData?.entries} />
				<Contact />
				<Footer />
			</main>
		</div>
	);
};

export default AboutPage;