import React, { useEffect, useState } from 'react';

import teamLidImg from '../../assets/images/teamlid.png';
import CarouselTeam from '../partials/carouselTeam';
import filterEmptyObjects from '../../utils/filterEmptyObjects';
import generateAssets from '../../utils/generateAssets';
import cleanHtml from '../../utils/cleanHtml';

const Team = ({ title, members }) => {
	return (
		<div className="team">
			<div className="teamContainer">
				<div className="teamLeden">
					<span className="teamTitle" dangerouslySetInnerHTML={cleanHtml(title)}></span>
					<span className="teamButton">
						<a href="mailto:info@primesafety.be" target="_blank">
							<button className="button blue">WORD DEEL VAN DE FAMILIE</button>
						</a>
					</span>
				</div>
				<div className="teamLeden">
					{filterEmptyObjects(members)?.map(
						(member, id) =>
							 (
								<div className="teamLidWrapper" key={id}>
									<div className="teamLidOverlay">
										<p>{member.title}</p>
										<p>{member.function}</p>
									</div>
									<img className="teamLid" key={id} src={generateAssets(member?.singleImage)} alt={`an image of the employee named ${member.title}`} />
								</div>
							)
					)}
				</div>
			</div>
			<div className="teamCarousel">
				<CarouselTeam teamMembers={filterEmptyObjects(members)} />
			</div>
		</div>
	);
};

export default Team;
