import React, { useState, useEffect } from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import generateAssets from '../../utils/generateAssets';

const CarouselTeam = ({ teamMembers }) => {
	const [active, setActive] = useState(false);
	return (
		<>
			<Carousel
				showThumbs={false}
				showStatus={false}
				showArrows={false}
				showIndicators={true}
				swipeable
				className="teamLeden"
				emulateTouch
				preventMovementUntilSwipeScrollTolerance
				swipeScrollTolerance={30}
			>
				{teamMembers?.map((member, id) => (
					<div className="teamLidWrapper" key={id}>
						<div className="teamLidOverlay">
							<p>{member.title}</p>
							<p>{member.function}</p>
						</div>
						<img className="teamLid" key={id} src={generateAssets(member?.singleImage)} alt={`an image of the employee named${member.title}`} />
					</div>
				))}
			</Carousel>
			<span className="teamTitle">
				<h3>
					het <span>prime safety</span> team
				</h3>
			</span>
			<span className="teamButton">
				<a href='mailto:info@primesafety.be' target="_blank">
					<button className="button blue">WORD DEEL VAN DE FAMILIE</button>
				</a>
			</span>
		</>
	);
};

export default CarouselTeam;
