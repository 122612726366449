import React from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel, renderIndicator } from 'react-responsive-carousel';

import sterren from '../../assets/images/sterren.png';
import prijskaartje from '../../assets/images/prijskaartje.png';
import winkelwagen from '../../assets/images/winkelwagen.png';
import handschoenenImg from '../../assets/images/handschoenen.png';
import Pillar from '../partials/pillar';
import generateAssets from '../../utils/generateAssets';

const Pillars = ({ pillars }) => {
	return (
		<div className="pillarsContainer">
			<img className="backgroundImg" src={handschoenenImg} alt="an image of gloves" />
			<div className="pillars">
				{pillars?.map((pillar, id) => {
					return (
						<div className={['pillar contentText']} key={id}>
							<Pillar icon={generateAssets(pillar.image)} title={pillar.reasonTitle} text={pillar.reasonText} />
						</div>
					);
				})}
			</div>
			<Carousel 
				className="carouselPillars" 
				showThumbs={false} 
				showStatus={false} 
				showArrows 
				swipeable
				emulateTouch
				preventMovementUntilSwipeScrollTolerance
				swipeScrollTolerance={30}
			>
				{pillars?.map((pillar, id) => {
					return (
						<div className={['pillar contentText']} key={id}>
							<Pillar icon={generateAssets(pillar.image)} title={pillar.reasonTitle} text={pillar.reasonText} />
						</div>
					);
				})}
			</Carousel>
		</div>
	);
};

export default Pillars;
