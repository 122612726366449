import React from 'react';
import cleanHtml from '../../utils/cleanHtml';

const Pillar = (props) => {
	return (
		<>
			<div className={['pillar contentText']}>
				<img src={props.icon} alt={`an image of ${props.title}`} />
				<h3>{props.title}</h3>
				<p dangerouslySetInnerHTML={cleanHtml(props.text)}></p>
			</div>
		</>
	);
};

export default Pillar;
